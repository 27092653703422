import React, { Component } from "react";
import * as R from "ramda";
import PropTypes from "prop-types";
import configs from "../../configs";
import { withApi } from "../contexts/ApiContext";
import NavBar from "../navigation/NavBar";
import Grid from "@material-ui/core/Grid";
import { ProjectGrid, ProjectGridContainer, ProjectGridContent, ErrorMessage } from "./ProjectGrid";
// import { Button } from "../inputs/Button";
import Footer from "../navigation/Footer";
import { withRouter, Link } from "react-router-dom";
// import LatestUpdate from "../whats-new/LatestUpdate";
import { connectMenu, ContextMenu, MenuItem } from "../layout/ContextMenu";
import styled from "styled-components";
import { ThreediumRequestsGrid } from "../threedium/ThreediumRequestsGrid";
import PrimaryLink from "../inputs/PrimaryLink";
import LiveLandsDialog from "../dialogs/LiveLandsDialog";
import { getThreediumRequests, unpublishProject } from "../../api/lib/api";
import { withUserContext } from "../contexts/UserContext";
import Modal from "react-modal";

import BannerImage from "../../assets/img_threediumbox.jpeg";
import Slider from "./Slider";

export const BannerThreedium = styled.section`
  margin-bottom: 50px;
  display: flex;
  flex: ${props => (props.flex === undefined ? 1 : props.flex)};
  justify-content: center;
  .content {
    max-width: 1200px;
    width: 100%;
    padding: 0 20px;
    text-decoration: none;
    a {
      text-decoration: none;
    }

    .box {
      cursor: pointer;
      border-radius: 3px;
      padding: 40px 30px;
      background-size: cover;
      background-position: center;
      text-decoration: none;
    }
  }

  &:first-child {
    padding-top: 30px;
  }
`;

export const ProjectsSection = styled.section`
  padding-bottom: 30px;
  display: flex;
  flex: ${props => (props.flex === undefined ? 1 : props.flex)};

  &:first-child {
    padding-top: 30px;
  }

  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 16px;
  }
`;

export const ProjectsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 20px;
`;

const WelcomeContainer = styled(ProjectsContainer)`
  .head-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  h1 {
    font-size: 15px;
  }
  h2 {
    line-height: 60px;
    font-size: 53px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  h4 {
    font-size: 20px;
    line-height: 24px;
  }

  img {
    max-width: 500px;
    height: auto;
  }
`;

export const ProjectsHeader = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h1 {
    font-size: 16px;
    font-weight: 600;
  }
`;

const contextMenuId = "project-menu";

class HomePage extends Component {
  static propTypes = {
    api: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    const isAuthenticated = this?.props?.userProvider?.state?.isLoggedIn;

    this.state = {
      projects: [],
      scenes: [],
      loading: isAuthenticated,
      isAuthenticated,
      error: null,
      threediumRequests: [],
      threediumLoading: false,
      showLiveLandsDialog: false,
      hasProjectsLoaded: false,
      reactGaInitialised: false,
      DialogComponent: null,
      dialogProps: {}
    };
  }

  componentDidUpdate(prevProps) {
    const wasAuthenticated = prevProps?.userProvider?.state?.isLoggedIn;
    const isAuthenticated = this?.props?.userProvider?.state?.isLoggedIn;

    if (wasAuthenticated == isAuthenticated) return;

    this.setState({ isAuthenticated });

    if (this.state.hasProjectsLoaded == false && isAuthenticated == true && wasAuthenticated == false) {
      // TODO HERE DO CALL TO GET THREEDIUM REQUEST
      this.loadProjects();
      this.getThreediumTiles();
    } else if (isAuthenticated == false && wasAuthenticated == true) {
      this.setState({ hasProjectsLoaded: false });
    }
  }

  getThreediumTiles = (page = 1) => {
    this.setState(s => ({ ...s, threediumLoading: true }));
    getThreediumRequests(page)
      .then(response => {
        const result = R.pathOr(false, ["data", "result"], response);
        this.setState(s => ({ ...s, threediumLoading: false }));
        if (result) {
          const requests = R.pathOr(false, ["data", "requests"], response);
          this.setState({
            loading: false,
            threediumRequests: requests
          });
        }
      })
      .catch(err => {
        console.error("getThreediumRequests", err);
        this.setState(s => ({ ...s, threediumLoading: false }));
      });
  };

  loadProjects = () => {
    this.props.api
      .getProjects()
      .then(response => {
        this.setState({
          projects: response.projects.map(project => ({
            ...project,
            url: `/projects/${project.project_id}`
          })),
          loading: false,
          hasProjectsLoaded: true
        });
      })
      .catch(error => {
        console.error(error);
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
          // User has an invalid auth token. Prompt them to login again.
          this.props.api.logout();
          return this.props.history.push("/login", { from: "/projects" });
        }
        this.setState({ error, loading: false });
      });
  };

  componentDidMount() {
    document.title = configs.longName();

    // We dont need to load projects if the user isn't logged in
    const isAuthenticated = this?.props?.userProvider?.state?.isLoggedIn;
    if (isAuthenticated) {
      this.loadProjects();
      this.getThreediumTiles();
    }
  }

  onDeleteProject = project => {
    this.props.api
      .deleteProject(project.project_id)
      .then(() => this.setState({ projects: this.state.projects.filter(p => p.project_id !== project.project_id) }))
      .catch(error => this.setState({ error }));
  };

  onUnpublishProject = project => {
    unpublishProject(project.project_id)
      .then(() => this.loadProjects())
      .catch(error => this.setState({ error }));
  };

  renderContextMenu = props => {
    return (
      <ContextMenu id={contextMenuId}>
        <MenuItem onClick={e => this.onDeleteProject(props.trigger.project, e)}>Delete Project</MenuItem>
      </ContextMenu>
    );
  };

  ProjectContextMenu = connectMenu(contextMenuId)(this.renderContextMenu);

  toggleLiveLandsDialog(show) {
    this.setState({ showLiveLandsDialog: show });
  }

  showDialog = (DialogComponent, dialogProps = {}) => {
    this.setState({
      DialogComponent,
      dialogProps
    });
  };

  hideDialog = () => {
    this.setState({
      DialogComponent: null,
      dialogProps: {}
    });
  };

  onNewProject = async () => {
    try {
      const res = await this.props.api.startNewProject(this.showDialog, this.hideDialog);
      console.log(`onNewProject success: ` + res);
      this.hideDialog();
      setTimeout(() => {
        this.props.history.push("/projects/new");
      }, 100);
    } catch (error) {
      console.error(error);
      this.hideDialog();
    }
  };

  render() {
    const {
      DialogComponent,
      dialogProps,
      error,
      loading,
      projects,
      scenes,
      isAuthenticated,
      threediumLoading,
      threediumRequests,
      showLiveLandsDialog
    } = this.state;

    const ProjectContextMenu = this.ProjectContextMenu;

    return (
      <>
        <NavBar />
        <div style={{ position: "fixed", top: 50, width: "100%", color: "#000", backgroundColor: "#fff", zIndex: 1000, padding: "10px 20px", boxShadow: "0 2px 4px rgba(0,0,0,0.1)" }}>
          <span style={{ marginRight: "20px" }}>Try the new version of the OVER Builder</span>
          <a href="https://builder.ovr.ai" style={{ textDecoration: "none", color: "#fff", fontWeight: "bold" }} className="HexButton --orange">Go to Builder v2</a>
        </div>  
        <main>
          <ProjectsSection flex={0}>
            <Slider />
          </ProjectsSection>
          <ProjectsSection>
            <ProjectsContainer>
              <ProjectsHeader>
                <h1>Projects</h1>
                {isAuthenticated && (
                  <PrimaryLink
                    className="--white "
                    to="#"
                    onClick={() => {
                      this.toggleLiveLandsDialog(true);
                    }}
                  >
                    Unpublish lands >
                  </PrimaryLink>
                )}
              </ProjectsHeader>
              <ProjectGridContainer>
                {/* <ProjectGridHeader>
                  <ProjectGridHeaderRow></ProjectGridHeaderRow>
                  <ProjectGridHeaderRow>
                    <Button as={Link} to="/projects/create">
                      New Project
                    </Button>
                  </ProjectGridHeaderRow>
                </ProjectGridHeader> */}
                <ProjectGridContent>
                  {error && <ErrorMessage>{error.message}</ErrorMessage>}
                  {!error && isAuthenticated && (
                    <ProjectGrid
                      loading={loading}
                      projects={projects}
                      scenes={scenes}
                      newProjectPath="/projects/templates"
                      contextMenuId={contextMenuId}
                      uploadFromDisk={true}
                      uploadFromDiskCb={json => {
                        this.props.history.push({
                          pathname: "/projects/load",
                          state: json
                        });
                      }}
                      onNewProject={this.onNewProject}
                      _GoToProject={project_url => this.props.history.push(project_url)}
                    />
                  )}
                  {!error && !isAuthenticated && "Please, login to browse your projects or to create a new one."}
                </ProjectGridContent>
              </ProjectGridContainer>
            </ProjectsContainer>
          </ProjectsSection>

          <BannerThreedium className="threedium-requests-banner">
            <div className="content">
              <Link to="/threedium/create">
                <div className="box" style={{ backgroundImage: `url(${BannerImage})` }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} style={{ display: "flex", alignItems: "center" }}>
                      <div>
                        <h3 style={{ fontSize: 37, fontWeight: 600 }}>
                          Elevate your experiences with premium 3D assets
                        </h3>
                        <p style={{ fontSize: 18, fontWeight: 600, marginTop: 30 }}>
                          take a look at the Threedium offers {">"}
                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ display: "flex", alignItems: "center" }}></Grid>
                  </Grid>
                </div>
              </Link>
            </div>
          </BannerThreedium>
          <ProjectsSection>
            {threediumRequests.length > 0 && (
              <ProjectsContainer>
                <ProjectsHeader>
                  <h1>Threedium Requests</h1>
                </ProjectsHeader>

                <ProjectGridContainer>
                  <ProjectGridContent>
                    {!error && isAuthenticated && (
                      <ThreediumRequestsGrid
                        loading={threediumLoading}
                        requests={threediumRequests}
                        newProjectPath="/threedium/create"
                        newProjectLabel="New 3D Threedium Request"
                        loadAction={null}
                        contextMenuId={contextMenuId}
                        callback={this.getThreediumTiles}
                      />
                    )}
                  </ProjectGridContent>
                </ProjectGridContainer>
              </ProjectsContainer>
            )}
          </ProjectsSection>

          <ProjectContextMenu />
          <LiveLandsDialog
            show={showLiveLandsDialog}
            onClose={() => {
              this.toggleLiveLandsDialog(false);
            }}
            loadProjects={this.loadProjects}
          />
        </main>
        <Footer />
        <Modal
          ariaHideApp={false}
          isOpen={!!DialogComponent}
          onRequestClose={this.hideDialog}
          shouldCloseOnOverlayClick={false}
          className="Modal"
          overlayClassName="Overlay"
        >
          {DialogComponent && (
            <DialogComponent onConfirm={this.hideDialog} onCancel={this.hideDialog} {...dialogProps} />
          )}
        </Modal>
      </>
    );
  }
}

export default withRouter(withApi(withUserContext(HomePage)));
